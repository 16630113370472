<template>
    <v-container>
        <section id="login-page">
            <div class="container my-5">
                <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card shadow p-3 bg-body rounded">
                    <div class="card-header mb-3 px-4">
                        <h3 class="card-title my-1 text-center">{{ $t("message.title-reset-password") }}</h3>
                    </div>
                    <div class="card-body p-3 m-1">
                        <form class="px-5" method="POST" @submit.prevent="resetPass">
                            <v-row>
                                <div>
                                    <label>
                                    {{ $t("message.label-email") }}
                                    </label>
                                </div>
                                <v-col cols="12" xs="2" class="input-class">
                                    <v-text-field
                                        type="email"
                                        outlined
                                        v-model="email"
                                        :error-messages="emailErrors"
                                        @input="$v.email.$touch()"
                                        @blur="$v.email.$touch()"
                                        required
                                    />
                                </v-col>
                                <div>
                                    <label>
                                        {{ $t("message.label-password") }}
                                    </label>
                                </div>
                                <v-col cols="12" xs="2" class="input-class">
                                    <v-text-field
                                        :type="showPassword ? 'text' : 'password'"
                                        :error-messages="passwordErrors"
                                        @input="$v.password.$touch()"
                                        @blur="$v.password.$touch()"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword = !showPassword"
                                        outlined
                                        v-model="password"
                                        required
                                        name="password"
                                        autocomplete="on"
                                    />
                                </v-col>
                                <div>
                                    <label>
                                        {{ $t("message.label-confirm-password") }}
                                    </label>
                                </div>
                                <v-col cols="12" xs="2" class="input-class">
                                    <v-text-field
                                        v-model="confirmPassword"
                                        :type="showConPassword ? 'text' : 'password'"
                                        :error-messages="confirmPasswordErrors"
                                        name="password"
                                        autocomplete="on"
                                        required
                                        @input="$v.confirmPassword.$touch()"
                                        @blur="$v.confirmPassword.$touch()"
                                        :append-icon="showConPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showConPassword = !showConPassword"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        <div class="d-grid  mt-4">
                            <v-btn type="submit" depressed color="primary" @submit.prevent="resetPass">
                            {{ $t("message.submit-button") }}
                            </v-btn>
                        </div>
                        <div class="d-flex flex-row justify-center mt-4">
                            <v-btn @click="$router.push('/login')" text color="primary">
                                {{ $t("message.back-button") }}
                            </v-btn>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
    name: "ResetPassword",
    mixins: [validationMixin],
    validations: {
        email: { required, email },
        password: { required, minLength: minLength(6) },
        confirmPassword: { required, sameAsPassword: sameAs("password") }
    },
    data() {
        return {
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConPassword: false,
        }
    },
    computed: {
        emailErrors() {
            let errors = [];
            if (!this.$v.email.$dirty) return errors;
            // !this.$v.email.email && errors.push("Must be valid e-mail");
            !this.$v.email.email && errors.push(this.$t("message.err-msg-email"));
            !this.$v.email.required &&
                errors.push(this.$t("message.err-msg-email-required"));
            this.err = errors;
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLength &&
                errors.push(this.$t("message.err-msg-password-minLength"));
            !this.$v.password.required && errors.push(this.$t("message.err-msg-password"));
            return errors;
        },
        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.confirmPassword.$dirty) return errors;
            !this.$v.confirmPassword.sameAsPassword &&
                errors.push(this.$t("message.err-msg-confPass-same"));
            !this.$v.password.required && errors.push(this.$t("message.err-msg-confPass"));
            return errors;
        },
    },
    methods: {
        resetPass() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString)
            const token = urlParams.get('token')
            let payload = {
                email: this.email,
                password: this.password,
                confirmPassword: this.confirmPassword,
                token: token
            }
            this.$store.dispatch("auth_module/resetPassword", payload)
        }
    }
}
</script>

<style>

</style>